/**
 * @graphql cc
 */
import gql from 'graphql-tag';
import { useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import type { ExecutionResult } from 'react-apollo';

import type { TreeDestinationPosition, TreeSourcePosition } from '@confluence/tree';

import type {
	useMovePageHandlerMovePageTopLevelMutation as useMovePageHandlerMovePageTopLevelMutation$data,
	useMovePageHandlerMovePageTopLevelMutationVariables as useMovePageHandlerMovePageTopLevelMutation$variables,
} from './__types__/useMovePageHandlerMovePageTopLevelMutation';
import type {
	useMovePageHandlerMovePageAppendMutation as useMovePageHandlerMovePageAppendMutation$data,
	useMovePageHandlerMovePageAppendMutationVariables as useMovePageHandlerMovePageAppendMutation$variables,
} from './__types__/useMovePageHandlerMovePageAppendMutation';
import type {
	useMovePageHandlerMovePageBeforeMutation as useMovePageHandlerMovePageBeforeMutation$data,
	useMovePageHandlerMovePageBeforeMutationVariables as useMovePageHandlerMovePageBeforeMutation$variables,
} from './__types__/useMovePageHandlerMovePageBeforeMutation';
import type {
	useMovePageHandlerMovePageAfterMutation as useMovePageHandlerMovePageAfterMutation$data,
	useMovePageHandlerMovePageAfterMutationVariables as useMovePageHandlerMovePageAfterMutation$variables,
} from './__types__/useMovePageHandlerMovePageAfterMutation';
import type { ContentTreeItem } from './data-transformers';

export type MovePageToNewParentParams = {
	pageId: string;
	parentId: string;
};
export type MovePageAlongsideSiblingParams = {
	pageId: string;
	siblingId: string;
	positionToSibling: 'before' | 'after';
};
export type MovePageTopLevelParams = {
	pageId: string;
	spaceKey: string;
};
export type MovePageParams =
	| MovePageToNewParentParams
	| MovePageAlongsideSiblingParams
	| MovePageTopLevelParams;
export type MovePageAdditionalParams = {
	contentStatus: string | null;
	destination: TreeDestinationPosition;
	movedPageTitle: string;
	source: TreeSourcePosition;
	sourceParent: ContentTreeItem;
	spaceId?: string | null;
	revertMove: () => MovePageParams;
};
export type OverrideMovePageParams = {
	newParentPageId?: string;
	newSpaceKey: string;
	targetId?: string;
	position?: string;
	closeMove?: () => void;
};
export type UnifiedMutationResult = {
	mutationResult: {
		page: {
			id: string | null;
			links: {
				webui: string | null;
				editui: string | null;
			} | null;
		} | null;
	} | null;
};
export type MovePageHandler = (
	params: MovePageParams,
) => Promise<ExecutionResult<UnifiedMutationResult>>;
export const useMovePageHandler = () => {
	const [movePageAfter] = useMutation<
		useMovePageHandlerMovePageAfterMutation$data,
		useMovePageHandlerMovePageAfterMutation$variables
	>(gql`
		mutation useMovePageHandlerMovePageAfterMutation($pageId: ID!, $siblingId: ID!) {
			movePageAfter(input: { pageId: $pageId, siblingId: $siblingId }) {
				page {
					id
					links {
						webui
						editui
					}
				}
			}
		}
	`);
	const [movePageBefore] = useMutation<
		useMovePageHandlerMovePageBeforeMutation$data,
		useMovePageHandlerMovePageBeforeMutation$variables
	>(gql`
		mutation useMovePageHandlerMovePageBeforeMutation($pageId: ID!, $siblingId: ID!) {
			movePageBefore(input: { pageId: $pageId, siblingId: $siblingId }) {
				page {
					id
					links {
						webui
						editui
					}
				}
			}
		}
	`);
	const [movePageAppend] = useMutation<
		useMovePageHandlerMovePageAppendMutation$data,
		useMovePageHandlerMovePageAppendMutation$variables
	>(gql`
		mutation useMovePageHandlerMovePageAppendMutation($pageId: ID!, $parentId: ID!) {
			movePageAppend(input: { pageId: $pageId, parentId: $parentId }) {
				page {
					id
					links {
						webui
						editui
					}
				}
			}
		}
	`);
	const [movePageTopLevel] = useMutation<
		useMovePageHandlerMovePageTopLevelMutation$data,
		useMovePageHandlerMovePageTopLevelMutation$variables
	>(gql`
		mutation useMovePageHandlerMovePageTopLevelMutation($pageId: ID!, $spaceKey: String!) {
			movePageTopLevel(input: { pageId: $pageId, targetSpaceKey: $spaceKey }) {
				page {
					id
					links {
						webui
						editui
					}
				}
			}
		}
	`);
	const movePage = useCallback<MovePageHandler>(
		async (params) => {
			const thenFn =
				<T>(resultFormatter: (data: T) => UnifiedMutationResult['mutationResult']) =>
				({ data, ...rest }: ExecutionResult<T>): ExecutionResult<UnifiedMutationResult> => {
					return {
						...rest,
						data:
							typeof data === 'undefined'
								? undefined
								: {
										mutationResult: resultFormatter(data),
									},
					};
				};
			if ('parentId' in params) {
				return movePageAppend({
					variables: { pageId: params.pageId, parentId: params.parentId },
				}).then(thenFn((data) => data.movePageAppend));
			} else if ('spaceKey' in params) {
				return movePageTopLevel({
					variables: { pageId: params.pageId, spaceKey: params.spaceKey },
				}).then(thenFn((data) => data.movePageTopLevel));
			} else if (params.positionToSibling === 'after') {
				return movePageAfter({
					variables: { pageId: params.pageId, siblingId: params.siblingId },
				}).then(thenFn((data) => data.movePageAfter));
			} else {
				return movePageBefore({
					variables: { pageId: params.pageId, siblingId: params.siblingId },
				}).then(thenFn((data) => data.movePageBefore));
			}
		},
		[movePageAfter, movePageAppend, movePageBefore, movePageTopLevel],
	);
	return { movePage };
};
